@keyframes bgMove{
  0% {
    background-position: center left 0;
  }
  100% {
    background-position: center left -100vw;
  }
}

/*err*/
.pageErrBox{text-align: center;padding: 70px 20px}
.pageErrTitle{font-size: 22px;font-weight: 700}
.pageErrText{margin: 14px 0 0;font-size: 14px}

.bTextColor{color:#A6D9FF}
.rTextColor{color:#F04C41}
.drTextColor{color:#FF0000}

.contentsSection{padding: 30px;height: 100vh;display: flex;align-items: center;justify-content: center;flex-direction: column}
.contentsSection.fullPage{padding: 0}
.basicImg{max-height: 28.13em}
.pageTitle{font-size: 7em;font-weight: 700;margin: 0.93em 0 0;text-align: center}

.mainVideoBox{width:100%;height: 100%}
.mainVideoBox video{display: block;width:100%;height: 100%;object-fit: cover}

.numberItem{border: 0.15em solid #0B0B0C;font-size: 8.56em;border-radius: 0.66em;background: #fff;color: #0B0B0C;font-weight: 700;padding: 0.28em 1.46em;position: relative;z-index: 1}
.numberItem:before{content: "";position: absolute;width: 0.29em;height: 0.29em;border-radius: 100%;right: 0.47em;top: 50%;transform: translateY(-50%);background: #6E6E77}
.numberItem:after{content: "";position: absolute;width: 0.29em;height: 0.29em;border-radius: 100%;left: 0.47em;top: 50%;transform: translateY(-50%);background: #6E6E77}
.numberArea{position: relative;text-align: center;width: 100%}
.numberBox{display: inline-block}
.numberBgBox{position: absolute;width: 100vw;padding: 0 0 10.78%;left: 50%;top: 50%;transform: translate(-50%, -50%);background: url(/assets/images/basic/bg_icon.svg) repeat-x center left;background-size: 100%;animation: bgMove 10s forwards infinite cubic-bezier(0.250, 0.250, 0.750, 0.750)}
.numberBgBox.bBg{background-color: #A6D9FF}
.numberBgBox.rBg{background-color: #FF7979}
  
.pageBtn{width: 100%;height: 55px;display: block;background: #73F7CA;border: 1px solid #73F7CA;color: #0B0B0C;font-weight: 700;font-size: 15px;border-radius: 0;padding: 5px 10px}
.pageBtn_box{padding: 0;background: transparent;position: fixed;left: 0;bottom: 0;width: 100%;z-index: 20}
.pageBtn_box.col2,.btn_subBox.col2{display: flex;justify-content: space-between}
.pageBtn_box.col2 .pageBtn{width: 50%}
.pageBtn + .pageBtn{background: #44D4A3;border-color: #44D4A3}

/*반응형*/
@media (max-width:1800px){

}
@media (max-width:1680px){
	
}
@media (max-width:1600px){
  html, body{font-size:15px}
}
@media (max-width:1440px){
  html, body{font-size:14px}
}
@media (max-width:1366px){
  html, body{font-size:13px}
}
@media (max-width:1280px){
  html, body{font-size:12px}
}
@media (max-width:1152px){
  html, body{font-size:11px}
}
@media (max-width:1024px){
  /* html, body{font-size:13px} */
}
@media (max-width:960px){
    
}
@media (max-width: 720px){
    
}
@media (max-width: 650px){
    
}
@media (max-width: 550px){
}
@media (max-width: 490px){
}
@media (max-width: 400px){
}
@media (max-width: 340px){
}
/*//*/